<template>
  <v-app>
    <v-container>
      <courseInforEditor />
    </v-container>
  </v-app>
</template>
<script>
import courseInforEditor from "@/components/course-model/course-infor-editor";

export default {
  components: {
    courseInforEditor,
  },
};
</script>
